<template>
  <div id="app">
    <template v-if="$isMobile">
      <MobileHeader class="header-custom" :class="{'header-style-scroll': isScroll}" ></MobileHeader>
      <router-view  :class="{'page-style-scroll': isScroll}"></router-view>
      <MobileFooter></MobileFooter>
      <div class="scroll-bar"  >
        <img src="../public/static/img/mobile/scroll_bar.png" alt="">
        <span class="btn to-up" @click.stop="scrollToTop" ></span>
        <span class="btn to-down" @click.stop="scrollToBottom" ></span>
      </div>
    </template>
     <template v-else>
      <Header class="header-custom" :class="{'header-style-scroll': isScroll}"></Header>
      <router-view :class="{'page-style-scroll': isScroll}"></router-view>
      <Footer></Footer>
    </template>
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/Footer";
import MobileHeader from "./components//MobileHeader";
import MobileFooter from "./components/MobileFooter";

export default {
  name: "app",
  components: {
    Header,
    Footer,
    MobileHeader,
    MobileFooter,
  },
  data() {
    return {
      isScroll: false
    };
  },
  mounted() {
    // this.listenScroll();
    window.addEventListener("scroll",this.listenScroll); 
  },
  watch: {
    $route() {
      let app = document.querySelector(".header-custom")
      app.scrollTop = 0;
    }
  },
  methods: {
    // linkto(path) {
    //   // let host = window.location;
    //   this.$router.push(path);
    // },
    listenScroll() {
       var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 76) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
      // let app = document.querySelector("#app");
      // let app = document.querySelector(".header-custom");

      // app.addEventListener("scroll", e => {
      //   let scrollTop = app.scrollTop;
      //   console.log(scrollTop);
      //   if (scrollTop >= 76) {
      //     this.isScroll = true;
      //   } else {
      //     this.isScroll = false;
      //   }
      // }); 
    },
    scrollToTop() {
    //   let app = document.querySelector("#app"));
    //   app.scrollTop = 0;
    },
    scrollToBottom() {
    //   let app = document.querySelector("#app"));
    //   app.scrollTop = app.scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .header-style-scroll {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background: #fff;
    padding-top: 0;
    box-shadow: 0 2px 4px 0 rgba(133, 138, 180, 0.14);
    transition: all 0.6s;
  }
  .page-style-scroll {
    padding-top: 80px;
    // transition: all 1s;
  }
  .scroll-bar {
    position: fixed;
    top: 50%;
    transform: translateY(-505);
    right: 8px;
    z-index: 1000;
    visibility: visible;
    // &.show {
    //   visibility: visible;
    // }
    img {
      width: 32px;
    }
    .btn {
      position: absolute;
      left: 0;
      height: 50%;
      display: block;
      width: 100%;
      &.to-up {
        top: 0;
      }
      &.to-down {
        top: 50%;
      }
    }
  }
}
</style>
