<script>
    let BASE_URL1 = ''// 设置全局 URL (Mars)
    let BASE_URL2 = ''//(Eric)
    let BASE_URL3 = '' //首页免费试用
    let BASE_URL4 = '' //船期查询
    if(window.location.host == 'www.dadaex.cn'){
        BASE_URL1='//www.dadaex.cn/apipy/v1'
        BASE_URL2='//www.dadaex.cn/apipy/v1'
        BASE_URL3='//dev.sfyf.cn/api'
        BASE_URL4='//www.dadaex.cn/api'
    }else {
        BASE_URL1='http://192.168.100.216:5000/v1' 
        BASE_URL2='http://192.168.100.158:8000/v1' 
        BASE_URL3='http://192.168.100.105:3001' 
        BASE_URL4='http://192.168.100.105:3001'
    }
    export default{
        BASE_URL1,
        BASE_URL2,
        BASE_URL3,
        BASE_URL4
    }
</script>