import Vue from 'vue'
import App from './App.vue'
import router from './route/index'
import { isMobile } from './config/client'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './style/reset.css'
import './style/common.scss'
import './style/index.scss'
import cookies from 'vue-cookies'
import moment from 'moment'
import axios from 'axios' 
import global_ from './components/Base.vue'
import { Message } from 'element-ui';
// import VueSocketIO from 'vue-socket.io'
Vue.prototype.$message = Message;
Vue.prototype.GLOBAL = global_;
axios.defaults.baseURL = global_.BASE_URL;
Vue.prototype.$ajax = axios
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.prototype.$isMobile = window.isMobile = isMobile
Vue.prototype.$cookies = cookies;
Vue.use(ElementUI);

axios.interceptors.response.use(response => {
  if (response) {
    switch (response.data.status_code) {
      case 403: //与后台约定登录失效的返回码,根据实际情况处理
        localStorage.removeItem("DDTtoken")    //删除用户信息
        //如果超时就处理 ，指定要跳转的页面(比如登陆页)
        // Message({
          // message: 'token失效,请重新登录!',
          // type: 'warning',
          // center: true,
          // onClose:function () {
          setTimeout(() => {
            router.replace({
              path: '/login',
            })
          }, 1000);
          // }
      // });
    }
  }
  return response;
}, error => {
  return Promise.reject(error.response.data) //返回接口返回的错误信息
})
// Vue.use(new VueSocketIO({
//   // 控制台调试 console.log
//   debug: false,
//   // 连接地址 后端提供
//   connection: 'http://192.168.100.216:5000',//本地
//   // connection: 'www.dadaex.cn/apipy'//线上
// }))

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  mounted () {
    // You'll need this for renderAfterDocumentEvent. 
    document.dispatchEvent(new Event('render-event'))
  }
})
