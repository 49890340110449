import {
  isMobile
} from '../config/client';

let Home, About, Law, CoreTech, More, ScheduleSeach, Register,
  FindPassword, VoucherCenter, MyProfile, UsageRecord, Login, Contract, 
  VgmSend, ContractSearch, Manifest, NewUsers, Api, OperatingRecord,
  RechargeRecord, CertificationProcess, FBA, quotedPrice, FBAOrder,
  PlanDetails, Binning, AMS
    

if (isMobile) {
  Home = () => import('../views/home/index_m.vue')
  About = () => import('../views/about/index_m.vue')
  Law = () => import('../views/law/index_m.vue')
  CoreTech = () => import('../views/core-tech/index_m.vue')
} else {
  Home = () => import('../views/home/index.vue')
  About = () => import('../views/about/index.vue')
  Law = () => import('../views/law/index.vue')
  CoreTech = () => import('../views/core-tech/index.vue')
  More = () => import('../views/More/More.vue')
  ScheduleSeach = () => import('../views/ScheduleSeach/ScheduleSeach.vue')
  Login = () => import('../views/Login/Login.vue')
  Register = () => import('../views/Register/Register.vue')
  FindPassword = () => import('../views/FindPassword/FindPassword.vue')
  VoucherCenter = () => import('../views/VoucherCenter/VoucherCenter.vue') 
  MyProfile = () => import('../views/MyProfile/MyProfile.vue')
  UsageRecord = () => import('../views/UsageRecord/UsageRecord.vue')
  Contract = () =>import('../views/Contract/Contract.vue')
  VgmSend = () => import('../views/VgmSend/VgmSend.vue')
  ContractSearch = () => import('../views/ContractSearch/ContractSearch.vue')
  Manifest = () => import('../views/Manifest/Manifest.vue')
  NewUsers = () => import('../views/NewUsers/NewUsers.vue')
  Api = () => import('../views/Api/Api.vue')
  OperatingRecord = () => import('../views/OperatingRecord/OperatingRecord.vue')
  RechargeRecord = () => import('../views/RechargeRecord/RechargeRecord.vue')
  CertificationProcess = () => import('../views/CertificationProcess/CertificationProcess.vue')
  FBA = () => import('../views/FBA/FBA.vue')
  quotedPrice = () => import('../views/FBA/quotedPrice/quotedPrice.vue')
  FBAOrder = () => import('../views/FBAOrder/FBAOrder.vue')
  PlanDetails = () => import('../views/FBA/PlanDetails/PlanDetails.vue')
  Binning = () => import('../views/Binning/Binning.vue')
  AMS = () => import('../views/AMS/AMS.vue')
}



const routes = [{
    component: Home,
    path: '/',
  },
  {
    component: About,
    path: '/about'
  },
  {
    component: Law,
    path: '/law'
  },
  {
    component: CoreTech,
    path: '/core-tech'
  },
  {
    component : More,
    path:'/more'
  },
  {
    component : ScheduleSeach,
    path: '/ScheduleSeach'
  },
  //登录
  {
    component: Login,
    path: '/login'
  },
  // 注册
  {
    component: Register,
    path: '/register'
  },
  // 找回密码
  {
    component: FindPassword,
    path: '/findPassword'
  },
  // 基础信息充值中心
  {
    component: VoucherCenter,
    path: '/voucherCenter'
  },
  // 基础信息-我的资料
  {
    component: MyProfile,
    path: '/myprofile'
  },
  // 基础信息-消费记录
  {
    component: UsageRecord,
    path: '/usageRecord'
  },
  // 电子合同-上传
  {
    component: Contract,
    path: '/contract'
  },
  // 电子合同-查询
  {
    component: ContractSearch,
    path: '/contractSearch'
  },
  // VGM发送
  {
    component: VgmSend,
    path:'/vgmSend'
  },
  // 舱单提交
  {
    component: Manifest,
    path: '/manifest'
  },
  // 我的副号
  {
    component: NewUsers,
    path: '/newUsers'
  },
  // api
  {
    component: Api,
    path: '/api'
  },
  // 操作记录
  {
    component: OperatingRecord,
    path:'/operatingRecord'
  },
  // 充值记录
  {
    component: RechargeRecord,
    path:'/rechargeRecord'
  },
  // 企业认证流程
  {
    component: CertificationProcess,
    path: '/certificationProcess'
  },
  // FBA页面(跨境电商)
  {
    component: FBA,
    path: '/fba'
  },
  // 跨境电商-报价页面
  {
    component: quotedPrice,
    path:'/quotedPrice'
  },
  // FBA-方案详情
  {
    component: PlanDetails,
    path:'/planDetails'
  },
  // 跨境电商-点单页面
  {
    component: FBAOrder,
    path:'/FBAOrder'
  },
  // 分箱工具
  {
    component: Binning,
    path: '/binning'
  },
  // AMS发送
  {
    component: AMS,
    path: '/ams'
  },
]


export default routes