<template>
  <div class="header-wrapper">
    <div class="header" >
      <div class="logo">
        <router-link to="/" ><img src="../../public/static/img/logo.png" alt=""></router-link>
      </div>
      <div class="menu-icon"  @click.stop="menuVisible=true" ></div>
      <div class="menu-wrapper" v-show="menuVisible" >
        <div class="menu-container" >
          <div class="menu-header">
            <span>产品服务</span>
            <img class="icon-close" src="../../public/static/img/icon_close.png" alt="" @click.stop="menuVisible=false" >
          </div>
          <div class="menu-list-1">
            <a class="menu-item" href="http://test.sfyf.cn/booking/newEbooking.html" target="_blank" >
              <img src="../../public/static/img/menu_icon_01.png" alt="">
              <span>报价查询</span>
            </a>
            <a class="menu-item" href="http://test.sfyf.cn/booking/frontlogin.html" target="_blank" >
              <img src="../../public/static/img/menu_icon_02.png" alt="">
              <span>在线订舱</span>
            </a>
            <a class="menu-item" href="http://test.sfyf.cn/booking/frontlogin.html" target="_blank">
              <img src="../../public/static/img/menu_icon_03.png" alt="">
              <span>舱单发送</span>
            </a>
            <a class="menu-item" href="http://bot.dadaex.cn" target="_blank" >
              <img src="../../public/static/img/menu_icon_04.png" alt="">
              <span>智能客服</span>
            </a>
          </div>
          <div class="menu-list-2">
            <router-link to="/core-tech" >核心技术</router-link>
            <a href="http://test.sfyf.cn/booking/frontlogin.html" target="_blank" >哒哒订舱</a>
            <a href="http://test.sfyf.cn/booking/frontlogin.html" target="_blank" >哒哒客服</a>
          </div>
          <a class="menu-footer" href="http://www.dadaex.cn/admin/user/register" target="_blank" >
            <span>免费试用</span>
            <img src="../../public/static/img/icon_arrow_right.png" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$fontColor: #5e2905;
$titleColor: #823c0d;

.header-wrapper {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 64px;
    background: #fff;
    padding: 0 24px;
    .logo {
      width: 100px;
      img {
        width: 100%;
      }
    }
    .menu-icon {
      width: 25px;
      height: 20px;
      background: url("../../public/static/img/icon_open_menu.png") no-repeat;
      background-size: 100% 100%;
    }

    .menu-wrapper {
      position: absolute;
      width: 300px;
      left: 50%;
      top: 80px;
      transform: translateX(-50%);
      color: $fontColor;
      z-index: 1000;
      .menu-container {
        padding: 14px 20px 0px;
        box-shadow: 0 4px 8px 0 rgba(44, 71, 146, 0.24);
        background: #fff;
        border-radius: 3px;
        .menu-header {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          .icon-close {
            width: 14px;
            height: 14px;
          }
        }
        .menu-list-1 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 16px;
          a {
            color: $fontColor;
          }
          .menu-item {
            height: 38px;
            width: 50%;
            padding-left: 14px;
            display: flex;
            align-items: center;
            img {
              width: 18px;
              height: auto;
              margin-right: 6px;
            }
          }
        }
        .menu-list-2 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          a {
            color: $fontColor;
          }
        }
        .menu-footer {
          display: block;
          border-top: 1px solid #e1e7ff;
          height: 40px;
          line-height: 40px;
          color: $fontColor;
          img {
            width: 16px;
            height: auto;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      menuVisible: false,
    }
  },
  watch: {
    $route(next, prev) {
      if (next.path !== prev.path) {
        this.menuVisible = false
      }
    }
  },
  methods: {
    
  }
}
</script>


