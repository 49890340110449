<template>
  <div class="header">
    <div class="header-inner area-wrapper">
      <div class="header-left">
        <img src="../../public/static/img/logo.png" alt="">
      </div>
      <div class="header-right">
        <ul class="header-nav">
          <li class="nav-item">
            <router-link to="/" >首页</router-link>
          </li>
          <li class="nav-item nav-item-option">
            <a href="javascript:void(0);" >产品服务</a>
            <span class="icon-down"></span>
            <div class="sub-nav-wrapper">
              <ul class="sub-nav">
                <!-- <li><router-link to="/ScheduleSeach">船期查询</router-link></li>
                <li><router-link to="vgmSend">VGM提交</router-link></li>
                <li><router-link to="manifest">舱单提交</router-link></li>
                <li><router-link to="api">API对接</router-link></li> -->
                <li><router-link to="fba">FBA</router-link></li>
                <li v-show="showFX"><router-link to="binning">分箱工具</router-link></li>
              <!-- <li><a href="">报价查询</a></li>
              <li><a href="">在线订舱</a></li>
              <li><a href="">舱单发送</a></li> -->
              <!-- <li><a href="/" >票据防伪</a></li> -->
              <li><a href="http://bot.dadaex.cn" target="_blank" >智能客服</a></li>
              <!-- <li> <router-link to="/shipSchedule" >船期查询</router-link></li> -->
            </ul>
            </div>
          </li>
          <li class="nav-item">
            <router-link to="/core-tech" >核心技术</router-link>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0);">哒哒订舱</a>
          </li>
          <li class="nav-item">
             <a href="javascript:void(0);" @click="showPop = true">货代系统</a>
          </li>
          <li class="nav-item">
             <router-link to="/ScheduleSeach"> 船期查询</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contract" >电子合同</router-link>
          </li>
          <!-- <li class="nav-item">
            <a href="http://bot.dadaex.cn" target="_blank" >哒哒客服</a>
          </li> -->
          <li class="nav-item  nav-item-option" v-show="userShow">
            <a href="javascript:void(0);" >用户中心</a>
            <span class="icon-down"></span>
            <div class="sub-nav-wrapper">
              <ul class="sub-nav">
               <li><router-link to="myprofile">基础设置</router-link></li>
               <li v-show="userAdmin">
                  <router-link to="newUsers">企业管理</router-link>
                </li>
               <li><router-link to="certificationProcess">企业认证</router-link></li>
               <li v-show="userAdmin"><router-link to="voucherCenter">充值中心</router-link></li>
               <li><router-link to="usageRecord">消费记录</router-link></li>
               <li><router-link to="operatingRecord">操作记录</router-link></li>
               <li v-show="userAdmin"><router-link to="/rechargeRecord">充值记录</router-link></li>
             </ul>
            </div>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/more" >帮助中心</router-link>
          </li> -->
          <li class="nav-items" v-if="!userShow">
            <router-link to="/login" >登录</router-link>
            /
            <router-link to="/register" >注册</router-link>
          </li>
          <li class="nav-items userMsg" v-if="userShow">
            <img v-if="icon" class="icon" :src="icon" alt="">
            <img v-if="!icon" class="icon" src="../assets/testImg/head.png">
            {{ userName }} | <span @click="quit">退出</span> 
          </li>
        </ul>
        <!-- <div class="btn-free">
          <a href="http://www.dadaex.cn/admin/user/register" target="_blank">免费试用</a>
        </div> -->
      </div>
    </div>
    <div class="dataChannel">
      <div class="dataChannelInner">
      <el-row :gutter="20">
         <el-col :span="4">
           <div class="typeInfo">
             信息发送通道
           </div>
         </el-col>
        <!-- <el-col :span="3">
          <img src="../assets/icon/schedule.png" alt="">
          <router-link to="/ScheduleSeach"> 船期查询</router-link>
        </el-col> -->
        <el-col :span="3">
          <img src="../assets/icon/vgm.png" alt="">
          <router-link to="vgmSend"> VGM提交</router-link>
        </el-col>
        <el-col :span="3">
          <img src="../assets/icon/manifest.png" alt="">
          <router-link to="manifest"> 舱单提交</router-link>
        </el-col>
        <el-col :span="3"  v-show="showFX">
          <img src="../assets/icon/manifest.png" alt="">
          <router-link to="ams">AMS发送</router-link>
        </el-col>
        <el-col :span="3" v-show="showFX">
          <img src="../assets/icon/manifest.png" alt="">
          <router-link to="afr">AFR发送</router-link>
        </el-col>
        <el-col :span="3" v-show="showFX">
          <img src="../assets/icon/manifest.png" alt="">
          <router-link to="isf">ISF发送</router-link>
        </el-col>
        <el-col :span="3">
          <img src="../assets/icon/API.png" alt="">
          <router-link to="api">API对接</router-link>
        </el-col>
      </el-row>
      </div>
    </div>
    <div class="freePopup" v-show="showPop">
      <div class="freePopupMain">
        <div class="freePopupTit">
          <h2>试用登录 <i class="el-icon-close" @click="closefreePopup"></i></h2>
        </div>
        <div class="freePopupCont">
          <div class="freePopupItem">
            <el-input v-model="number" placeholder="请输入手机号"></el-input>
          </div>
          <div class="freePopupItem">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-input v-model="captcha" placeholder="请输入手机验证码" maxlength="6"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="success" v-show="!sendCode" @click="toSendCode()">获取验证码</el-button>
                <p v-show="sendCode">重新发送({{count}})s</p>
              </el-col>
            </el-row>
          </div>
          <div class="freePopupBtn">
             <el-button type="warning" @click="loginNow()">立即登录</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from '../bus'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
  data(){
    return{
      index:1,
      show: false,
      userName:'',
      icon:'',
      userShow:false,//是否登录
      userAdmin:false,//是否是管理员
      number:'',
      captcha:'',
      checkToken:'',
      showPop:false,
      sendCode:false,
      count:60,
      showFX: window.location.host !== 'www.dadaex.cn'
    }
  },
  created(){
    // 登录成功
    bus.$on('useMsg',val=>{
      if(val){
        this.userShow = true
        this.userName = val.data.username
        if(val.data.icon){
          this.icon = val.data.icon
        }
        this.icon = val.data.icon
        if(val.data.admin == 1 && val.data.status == 1){
          this.userAdmin = true
        }else {
          this.userAdmin = false
        }
      }else {
        this.userShow = false
      }
    })
    // 企业认证更新token
    bus.$on('cerUserMsg',val=>{
        if(val){
          this.userShow = true
          this.userName = val.name
          if(val.icon){
            this.icon = val.icon
          }
          this.icon = val.icon
          if(val.admin == 1 && val.status == 1){
            this.userAdmin = true
          }else {
            this.userAdmin = false
          }
        }else {
          this.userShow = false
        }
      })
    if(localStorage.getItem('DDTtoken')){
      var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
      // token失效自动登出
      bus.$on('logout',val=>{
        if(val){
          this.userShow = false
          this.userAdmin = false
        }
      })
      if(decoded.name){
        this.userShow = true
        this.userName = decoded.name
        this.icon = decoded.icon
        if(decoded.admin == 1 && decoded.status == 1){
          this.userAdmin = true
        }else {
          this.userAdmin = false
        }
      }else {
        this.userShow = false
      }
    }
  },
  methods:{
    toTab(item){
      this.show = true
      this.index = item
    },
    cancle(){
      this.show = false
    },
    // 登出
    quit(){
      this.userShow = false
      this.userAdmin = false
      localStorage.removeItem("DDTtoken")
      this.$router.push('/login')
    },
    // 货代系统
    freeTry(){
      this.showPop = true
    },
    closefreePopup(){
      this.showPop = false
      this.number=''
      this.captcha=''
      this.checkToken=''
    },
     // 验证手机号
    checkPhone(val) {
        var myreg = /(^1|^84)(\d{10}|\d{9})$/;
        if(!myreg.test(val)) {
            return false
        } else {
            return true
        }
    },
    // 发送验证码
    toSendCode(){
      if(!this.number) {
        this.$message({
          type: 'warning',
          message: "请输入手机号",
          center:true,
          showClose: true,
        });
      }else if(!this.checkPhone(this.number)){
         this.$message({
          type: 'warning',
          message: "请输入正确格式的手机号",
          center:true,
          showClose: true,
        });
      }else {
        axios.get(this.GLOBAL.BASE_URL3+'/user/captchas',{
          params:{
            number:this.number
          }
        })
        .then (res=>{
          if(res.data.token){
            this.checkToken = res.data.token
            this.sendCode = true
            var countTimer = setInterval(()=>{
              this.count --
              if(this.count === 0) {
                clearInterval(countTimer)
                this.sendCode = false
                this.count = 60
              }
            },1000) 
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              center:true,
              showClose: true,
            });
          }
        })
        .catch(error=> {
          console.log(error);
          this.$message({
            type: 'error',
            message: error.errors,
            center:true,
            showClose: true,
          });
        })
      }
      
    },
    loginNow(){
      if(!this.number) {
        this.$message({
          type: 'warning',
          message: "请输入手机号",
          center:true,
          showClose: true,
        });
      }else if(!this.checkPhone(this.number)){
         this.$message({
          type: 'warning',
          message: "请输入正确格式的手机号",
          center:true,
          showClose: true,
        });
      }else if(!this.captcha){
         this.$message({
          type: 'warning',
          message: "请输入验证码",
          center:true,
          showClose: true,
        });
      } else if(!this.checkToken){
        this.$message({
          type: 'warning',
          message: "请重新获取验证码",
          center:true,
          showClose: true,
        });
      }else {
        axios.defaults.headers.common['Authorization'] = this.checkToken
        axios.get(this.GLOBAL.BASE_URL3+'/user/login/mobiles',{
          params:{
            number:this.number,
            captcha:this.captcha
          }
        })
        .then(res=>{
          if(res.data.token){
            var url = ''
            window.location.host == 'www.dadaex.cn' ? url = 'http://ebsimple.dadaex.cn/' : url = 'http://localhost:8001/'
            window.open(url+'user/login?token='+ res.data.token)
            this.closefreePopup()
          }else {
            this.$message({
              type: 'error',
              message: res.data.message,
              center:true,
              showClose: true,
            });
          }
        })
        .catch (error=>{
          console.log(error);
          this.$message({
            type: 'error',
            message: error.errors,
            center:true,
            showClose: true,
          });
        })
      }
    }
  },
  
}
</script>
<style lang="scss" scoped>
@import "../style/mixin.scss";

@mixin btnStyle {
  height: 36px;
  cursor: pointer;
  transition: all 0.2s;
  background: $btnBgColor;
  box-shadow: 0 3px 4px 0 #c85b0a;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 36px;
  user-select: none;
}
$fontSize: 16px;
.header {
  height: 110px;
  // padding-top: 16px;
  .header-inner {
    height: 66px;
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-left {
      height: 40px;
      img {
        height: 100%;
      }
    }
    .header-right {
      display: flex;
    }
    .header-nav {
      // margin-right: 40px;
      .nav-items {
        display: inline-block;
        height: 36px;
        width: 100px;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        color: #f0801b;
        cursor: pointer;
        a {
          color: #f0801b;
          padding-bottom: 3px;
        }
        a:hover{
          border-bottom: 2px solid #f0801b;
        }
      }
      .nav-item {
        position: relative;
        display: inline-block;
        height: 36px;
        // width: 115px;
        width: 100px;
        line-height: 36px;
        margin-right: 4px;
        text-align: center;
        &.nav-item-option {
          text-align: left;
          padding-left: 14px;
        }
        &:hover {
          @include btnStyle;
          > a {
            color: #fff;
          }
          .icon-down {
            border-color: #fff;
          }
          .sub-nav-wrapper {
            visibility: visible;
          }
        }
        > a {
          display: inline-block;
          height: 100%;
          width: 100%;
          font-size: $fontSize;
          color: $themeColor;
          // font-weight: 500;
        }
        .icon-down {
          position: absolute;
          right: 10px;
          top: 11px;
          display: inline-block;
          height: 7px;
          width: 7px;
          border-right: 1px solid #f5ad6b;
          border-bottom: 1px solid #f5ad6b;
          transform: rotate(45deg);
        }
      }
      .sub-nav-wrapper {
        position: absolute;
        padding: 6px 0;
        visibility: hidden;
        width: 100%;
        top: 36px;
        left: 0;
        padding-top: 4px;
        z-index: 11;
        a {
          font-weight: 400;
        }
      }
      .sub-nav {
        background-color: #fff;
        box-shadow: 0 -1px 2px 0 rgba(225, 231, 255, 0.3),
          0 4px 8px 0 rgba(133, 138, 180, 0.32);
        border-radius: 3px;
        li {
          line-height: 26px;
          text-align: center;
          a {
            color: #f5ad6b;
            display: block;
            width: 100%;
            height: 100%;
          }
          &:hover {
            background: rgb(255, 248, 243);
            a {
              color: $themeColor;
            }
          }
        }
      }
    }

    .btn-free {
      @include btnStyle;
      width: 120px;
      text-align: center;
      user-select: none;
      font-size: $fontSize;
      a {
        color: #fff;
      }
    }
  }
  .dataChannel {
    width: 100%;
    padding: 7px 0;
    background: #0b1139;
    .typeInfo {
      padding: 2px 15px;
      line-height: 1.6;
      color: #fff;
      font-size: 15px;
      text-align: center;
      border-radius: 3px;
      background-color: #ef7609;
      position: relative;
      margin-right: 27px;

    }
    .typeInfo::after {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%) translateX(100%);
      transform: translateY(-50%) translateX(100%);
      display: block;
      border: 5px solid #0b1139;
      border-left: 8px solid #ef7609;
      content: "";
    }
    .dataChannelInner {
      width: 1200px;
      margin: auto;
      .el-row {
        width: 100%;
        height: 100%;
        line-height: 30px;
        .el-col {
          align-items: center;
          display: flex;
          img {
            width: 20px;
            height: 20px;
            align-items: center;
            margin-right: 8px;
          }
          a {
            color: #fff;
            font-size: 15px;
            letter-spacing:1px
          }
          a:hover {
            color: #ff912e;
          }
        }
      }
      
    }
  }
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../public/static/img/background.jpg) no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  z-index: 999999;
}
.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  .popInner {
    width: 500px;
    height: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000000000;
    background: #fff;
    border-radius: 10px;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 5px 8px;
      border: 1px solid #ccc;
      border-radius: 15px;
      background: #fff;
      cursor: pointer;
      font-size: 14px;
      color: #ccc;
    }
    .close:hover {
      color: #fff;
      background: rgba($color: #f5ad6b, $alpha: .5);
      border: 1px solid #fff;
    }
    .popLogo {
      width: 100%;
      height: 150px;
      margin: auto;
      padding: 50px 188px;
    }
    .popTit {
      height: 100px;
      width: 100%;
      line-height: 80px;
      font-size: 30px;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 50px;
        cursor: pointer;
      }
      li:hover {
        color: #f0801b;
      }
      .act {
        color: #f0801b;
      }
    }
    .popCont {
      padding: 0 50px;
      input {
        outline: none;
      }
      .userDiv,.passDiv {
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        span {
          font-size: 14px;
          color: #333333;
          line-height: 1.5;
          padding-left: 7px;
        }
        input {
          font-size: 16px;
          color: #333333;
          line-height: 1.2;
          display: block;
          width: 100%;
          height: 55px;
          background: transparent;
          padding: 0 7px 0 43px;
          border: none;
          border-bottom: 2px solid #ccc;
        }
        input:focus{
          border-bottom: 2px solid #f0801b;
          
        }
        i {
          display: block;
          position: absolute;
          width: 43px;
          height: 55px;
          left: 0;
          bottom: 0;
        }
      }
      .userDiv i {
        background: url(../../public/static/img/username.png) no-repeat center;
        background-size: 60%;
      }
      .passDiv i {
         background: url(../../public/static/img/password.png) no-repeat center;
         background-size: 60%;
      }
       .userDiv input:focus + i {
            background: url(../../public/static/img/usernameAct.png) no-repeat center;
            background-size: 60%;
          }
        .passDiv input:focus + i {
            background: url(../../public/static/img/passwordAct.png) no-repeat center;
            background-size: 60%;
          }
      .forgetDiv {
        padding: 10px 0 30px;
        text-align: right;
        a {
          color: #666;
          font-size: 16px;
        }
        a:hover {
          color: #f0801b;
          font-size: 16px;
        }
      }
      .logIn,.signIn {
        font-size: 18px;
        color: #fff;
        line-height: 1.2;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        width: 100%;
        height: 50px;
        outline: none !important;
        border: none;
        background: transparent;
        background: -webkit-linear-gradient(left, #acc7f6, #fdf808, #fe9e37, #d67b69);
        border-radius: 25px;
      }
      .signIn {
        margin-top: 40px;
      }
    }
  }
}
.userMsg {
  position: relative;
  width: 135px !important;
  .icon {
    position: absolute;
    top: 5px;
    left: -3px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
.freePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background: rgba(0,0,0,.5);
    z-index: 9;
    .freePopupMain {
      width: 400px;
      height: auto;
      padding: 25px;
      background: #fff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border-radius: 5px;
      .freePopupTit {
        padding: 10px 0 15px;
        h2 {
          position: relative;
          font-size: 25px;
          i {
            position: absolute;
            top: -10px;
            right: 0;
          }
        }
      }
      .freePopupCont {
        padding: 10px;
        .freePopupItem {
          margin-bottom: 30px;
          .el-input{
            .el-input__inner:focus {
              border-color: #ef7609 !important;
            }
          }
          p {
            color: #999;
            line-height: 40px;
          }
          .el-button {
            padding: 12px 10px;
          }
        }
        .freePopupBtn {
          .el-button {
            width: 100%;
          }
        }
      }
    }
  }



</style>
